<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.chats')" class="item_inbox chats">
    <CCol cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <div class="w-100 d-flex align-items-center">
            <div class="flex-grow-1">
              <span>{{$t('connect.Chats')}}</span>
              <span v-if="searchedChats.length > 0" class="ml-1">({{searchedChats.length}})</span>
            </div>
            <div class="ml-2">
              <CButton class="m-0" color="primary" @click="openSidebarRight('connect_chat_details', { thread_id_external: null })">
                <i class="fas fa-plus mr-1"/>
                <span>{{$t('connect.New_chat')}}</span>
              </CButton>               
            </div>
          </div>
        </CCardHeader>
        <CCardBody class="items chats pt-0 pb-0">
          <CRow class="h-100">
            <CCol cols="12" lg="12" class="pb-0">
              <div class="mb-2_5">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <CButtonGroup class="w-100">
                      <CButton color="outline-secondary"
                               v-for="(value, name, key) in [$t('connect.All_chats'), $t('connect.Personal_chats'), $t('connect.Group_chats')]"
                               :key="key"
                               :pressed="value === chatsToShow ? true : false"
                               @click="chatsToShow = value; getChats();">
                        {{value}}
                      </CButton>
                    </CButtonGroup>
                  </CCol>
                </CRow>
              </div>              
              <div class="mb-2_5">
                <CInput class="mb-0 searchbar" type="text" v-model="contentSearchTerm" :placeholder="$t('Search_within') + ' ' + $t('connect.chats')"/>
              </div>
              <CRow v-if="searchedChats.length > 0" class="item_container add_top_border">
                <CCol cols="12" lg="12" class="p-0">
                  <CRow class="item chat m-0"                        
                        v-for="chat in searchedChats"
                        v-bind:key="chat.thread_id_external"
                        v-bind:class="{active: chat.thread_id_external === activeChat.thread_id_external, 'updated' : chat.update_available === true && chat.total_thread_messages > 0}"
                        @click="getChatData(chat.thread_id_external, false, true);
                                resetNewChatReply();
                                countdown();">
                                        
                    <CCol cols="12" lg="12">
                      <div class="d-flex align-items-center">
                        <div v-if="chat.thread_type_tag === 'connect_chat'" class="participant single mr-2">                    
                          <div class="d-flex align-items-center justify-content-center">                                      
                            <div v-if="chat.participant.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + chat.participant.user_id_external + '/' + chat.participant.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon">
                              <userProfileAvatar :username="chat.participant.name" :size="40"/>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="chat.thread_type_tag === 'connect_group_chat'" class="mr-2">
                          <div v-if="chat.thread_image_id" class="item_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/connect-chat/' + chat.thread_id_external + '/' + chat.thread_image_id + '/' + clientToken + ')' }"></div>
                          <div v-else class="item_icon">
                            <i class="fa-solid fa-people-group"></i>
                          </div>                                  
                        </div>
                        <div class="flex-grow-1">
                          <div class="d-flex align-items-center" v-bind:class="{'mb-1' : chat.last_message}">                               
                            <div class="flex-grow-1 subject">
                              <span v-if="chat.thread_type_tag === 'connect_chat'" v-line-clamp="1">{{chat.participant.name}}</span>
                              <span v-else v-line-clamp="1">{{chat.subject}}</span>
                            </div>
                            <div class="meta ml-4">
                              <span v-if="chat.last_message" v-line-clamp="1">{{chat.last_message.created | moment("DD-MM-YYYY HH:mm")}}</span>
                              <span v-else v-line-clamp="1">{{chat.created | moment("DD-MM-YYYY HH:mm")}}</span>
                            </div>                              
                          </div>
                          <div v-if="chat.last_message" class="d-flex align-items-center last_message">
                            <div class="flex-grow-1">
                              <div v-if="chat.last_message.content">                              
                                <span v-if="chat.thread_type_tag === 'connect_group_chat'" v-line-clamp="1">{{chat.last_message.user_message ? $t('You') : chat.last_message.name }}: {{chat.last_message.content}}</span>
                                <span v-else v-line-clamp="1">{{chat.last_message.content}}</span>                              
                              </div>                                
                              <div v-else-if="chat.last_message.attachments">
                                <div v-if="chat.last_message.attachments.files">
                                  <span v-if="chat.thread_type_tag === 'connect_group_chat'">{{chat.last_message.user_message ? $t('You') : chat.last_message.name }}: </span>
                                  <span><i class="fa-solid fa-file-lines mr-1"></i>{{chat.last_message.attachments.files.length > 1 ? $t('common.Files') : $t('common.File')}}</span>
                                </div>
                                <div v-if="chat.last_message.attachments.images">
                                  <span v-if="chat.thread_type_tag === 'connect_group_chat'">{{chat.last_message.user_message ? $t('You') : chat.last_message.name }}: </span>
                                  <span><i class="fa-solid fa-file-image mr-1"></i>{{chat.last_message.attachments.images.length > 1 ? $t('common.Images') : $t('common.Image')}}</span>                                  
                                </div>
                              </div>
                            </div>
                            <div v-if="chat.update_available === true" class="ml-2 new_messages">
                              <span>{{chat.total_new_thread_messages}}</span>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow v-else class="m-0">
                <CCol cols="12" lg="12" class="p-0">
                  <span>{{$t('connect.No_chats_available')}}</span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :cols="activeChatDetailsHidden ? 9 : 6" :lg="activeChatDetailsHidden ? 9 : 6" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <div v-if="activeChat.thread_id_external !== null" class="w-100 d-flex align-items-center">
            <div @click="activeChat.thread_type_tag === 'connect_chat' ? openSidebarRight('user_profile', { user_id_external:  activeChat.participant.user_id_external }) : activeChatDetailsHidden = false;"
                 class="d-flex align-items-center flex-grow-1 pointer">
              <div v-if="activeChat.thread_type_tag === 'connect_chat'" class="participant">                    
                <div v-if="activeChat.participant.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + activeChat.participant.user_id_external + '/' + activeChat.participant.profile_image + '/' + clientToken + ')' }"></div>
                <div v-else class="profile_icon">
                  <userProfileAvatar :username="activeChat.participant.name" :size="30"/>
                </div>
              </div>
              <div v-else-if="activeChat.thread_type_tag === 'connect_group_chat'">
                <div v-if="activeChat.thread_image_id" class="item_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/connect-chat/' + activeChat.thread_id_external + '/' + activeChat.thread_image_id + '/' + clientToken + ')' }"></div>
                <div v-else class="item_icon">
                  <i class="fa-solid fa-people-group"></i>
                </div>                                  
              </div>
              <div class="ml-2">
                <span v-if="activeChat.thread_type_tag === 'connect_chat'">{{activeChat.participant.name}}</span>
                <span v-else>{{activeChat.subject}}</span>
              </div>
            </div>
          </div>
          <div v-else class="w-100 d-flex align-items-center">
            <span>{{$t('common.Messages')}}</span>
          </div>
        </CCardHeader>
        <CCardBody class="item_messages" v-bind:class="{'p-0' : activeChat.thread_id_external}">
          <CRow v-if="activeChat.thread_id_external !== null" class="h-100 m-0">
            <CCol cols="12" lg="12" class="p-0">
              <div id="itemMessageContainer" ref="itemMessageContainer" class="item_message_container" v-bind:class="{'hidden' : activeChatMessagesHidden}" @scroll="updateChatMessages()">
                <loadingSpinner v-if="activeChatMessagesLoading" mode="auto" size="small" :content="null" class="mb-2_5"/>
                <div class="w-100" v-if="activeChatMessages.length > 0">
                  <div v-for="(message, index) in activeChatMessages"
                       v-bind:key="message.thread_message_id_external"
                       class="d-flex item_message"
                       v-bind:class="{ left : !message.user_message, right : message.user_message, grouped : checkGroupedMessage(index) }"
                       v-observe-visibility=" {callback: (isVisible, entry) => visibilityChanged(isVisible, entry, message.thread_message_id_external), throttle: 2000, once: true }">

                    <div class="icon" v-bind:class="{ 'ml-2' : message.user_message, 'mr-2' : !message.user_message}">
                      <div v-if="message.user_message" 
                           class="profile_image"
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + userData.user_id_external + '/' + userData.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="!message.user_message && message.profile_image"
                           class="profile_image"
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + message.user_id_external + '/' + message.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <userProfileAvatar v-else :username="message.name" :size="25"/>
                    </div>
                    <div class="flex-grow-1">
                      <div class="content">
                        <div v-if="activeChat.thread_type_tag === 'connect_group_chat'" class="name">
                          <span><b>{{message.name}}</b></span>
                        </div>                       
                        <div v-if="message.attachments.total_attachments > 0" class="attachments" v-bind:class="{'mt-0' : activeChat.thread_type_tag === 'connect_chat'}">                            
                          <div v-if="message.attachments.images && message.attachments.images.length > 0"
                                class="image_grid"
                                v-bind:class="{'grid_1' : message.attachments.images.length == 1, 
                                               'grid_2' : message.attachments.images.length == 2, 
                                               'grid_3' : message.attachments.images.length >= 3}">
                                                      
                            <div v-for="(image, index) in message.attachments.images.slice(0, maxGridImages)" v-bind:key="index" v-bind:style="{ backgroundImage: 'url(' + image.url + ')' }" @click="openImageLightbox(message.attachments.images, index)" class="image_grid_item">
                              <div v-if="index == maxGridImages - 1 && message.attachments.images.length > 3" class="grid_more h-100 w-100">
                                <span>{{message.attachments.images.length - maxGridImages}}+</span>
                              </div>
                            </div>
                          </div>

                          <CoolLightBox v-if="message.attachments.images && message.attachments.images.length > 0" 
                                        :items="lightboxImages"
                                        :index="lightboxActiveIndex"
                                        :slideshow="false"
                                        :enableScrollLock="true"
                                        @close="lightboxActiveIndex = null">
                          </CoolLightBox>

                          <div v-if="message.attachments.files && message.attachments.files.length > 0">
                            <div v-for="(file, index) in message.attachments.files" v-bind:key="index" class="file">
                              <div class="w-100 d-flex align-items-center pointer">
                                <div class="flex-grow-1">
                                  <div class="d-flex align-items-center" @click="file.file_extension === 'pdf' ? openFileAttachment(file.url) : null">
                                    <div class="icon mr-1">
                                      <i v-if="file.file_extension === 'pdf'" class="fas fa-file-pdf"></i>                                
                                      <i v-if="file.file_extension === 'ppt' || file.file_extension === 'pptx' || file.file_extension === 'odp'" class="fas fa-file-powerpoint"></i>
                                      <i v-if="file.file_extension === 'doc' || file.file_extension === 'docx' || file.file_extension === 'odt'" class="fas fa-file-word"></i>
                                      <i v-if="file.file_extension === 'xls' || file.file_extension === 'xlsx' || file.file_extension === 'ods'" class="fas fa-file-excel"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{file.attachment_name}}</span>
                                    </div>
                                  </div>
                                </div>
                                <div @click="downloadFileAttachment(file.attachment_name, file.url)" class="d-flex align-items-center">
                                  <span class="material-icons">file_download</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="message.content" class="text" v-bind:class="{'mt-0' : (activeChat.thread_type_tag === 'connect_chat' || checkGroupedMessage(index)) && message.attachments.total_attachments === 0}">                          
                          <span>{{message.content}}</span>
                        </div>
                        <div class="meta">
                          <span>{{message.created | moment("DD-MM-YYYY HH:mm")}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-0">
              <div v-if="addNewReplyAttachment" class="add_attachment">
                <CRow>
                  <CCol cols="12" lg="12" class="pb-0">
                    <div class="pointer" @click="resetAttachment()">
                      <i class="fas fa-times"></i>
                    </div>
                    <div v-if="showAttachmentTypes" class="attachment_types">
                      <CRow>
                        <CCol class="pb-0" v-for="type in attachmentTypes" v-bind:key="type.attachment_type_tag">                                          
                          <div class="d-flex flex-column justify-content-center align-items-center pointer attachment_type" @click="setAttachmentType(type);">
                            <div class="attachment_type_icon">                              
                              <i v-if="type.attachment_type_tag === 'image'" class="fas fa-image"></i>
                              <i v-if="type.attachment_type_tag === 'file'" class="fas fa-file-alt"></i>                              
                            </div>
                            <span>{{type.attachment_type}}</span>
                          </div>                      
                        </CCol>
                      </CRow>
                    </div>

                    <div v-if="selectedAttachmentTypeTag == 'image'">
                      <input id="imageUpload"
                            type="file"
                            multiple
                            accept="image/png, image/gif, image/jpeg"
                            :value="newChatReply.uploaded_images"
                            @change="onImagesChange" 
                            hidden>

                      <div v-if="newChatReply.attachments.length > 0" id="preview" class="mt-1 attachment_images_preview">
                        <div v-for="(image, index) in newChatReply.attachments" :key="index" class="attachment_image">
                          <img :src="image.image_url"/>                                          
                        </div>                          
                      </div>
                    </div>

                    <div v-if="selectedAttachmentTypeTag == 'file'">
                      <input id="fileUpload"
                            type="file" 
                            multiple                            
                            accept="application/pdf,
                                    application/msword,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                    application/vnd.oasis.opendocument.text,
                                    application/vnd.ms-powerpoint,
                                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                                    application/vnd.oasis.opendocument.presentation,
                                    application/vnd.ms-excel,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                    application/vnd.oasis.opendocument.spreadsheet"
                            :value="newChatReply.uploaded_files"
                            @change="onFilesChange" 
                            hidden>

                      <div v-if="newChatReply.attachments.length > 0" id="preview" class="mt-1 attachment_documents">
                        <div v-for="(document, index) in newChatReply.attachments" :key="index" class="attachment_document">                        
                          <span><i class="fas fa-file mr-1"/>{{document.file_name}}</span>
                        </div>                          
                      </div>      
                    </div>                    
                    
                  </CCol>
                </CRow>
              </div>
              <div class="your_response">
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pt-0' : addNewReplyAttachment}">
                    <div class="w-100 d-flex">
                      <div class="flex-grow-1">
                        <CTextarea @input="countdown()" maxlength="1000" rows="3" class="m-0 flex-grow-1" type="text" v-model="newChatReply.reply" :placeholder="$t('service.Your_reply')"/>
                        <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{$t('common.characters_remaining')}}</p>
                      </div>
                      <CButton color="primary" 
                               class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center"
                               @click="addNewReplyAttachment = true; showAttachmentTypes = true;"
                               :disabled="addNewReplyAttachment || chatReplyButtonDisabled">
                        <span class="material-icons text-center">attachment</span>
                      </CButton>
                      <CButton color="primary"
                               class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center" 
                               @click="submitChatReply(activeChat.thread_id_external);"
                               :disabled="(!newChatReply.reply && newChatReply.attachments.length === 0) || chatReplyButtonDisabled">
                        <span class="material-icons text-center">send</span>
                      </CButton>                     
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
          <CRow v-else-if="activeChat.thread_id_external === null">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('connect.Select_chat_to_view_messages')}}</span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol v-if="!activeChatDetailsHidden" cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <div class="w-100 d-flex align-items-center">
            <div class="flex-grow-1">
              <span>{{$t('common.Details')}}</span>
            </div>
            <div class="ml-2">
              <i class="fas fa-xmark pointer" @click="activeChatDetailsHidden = true;"/>
            </div>
          </div>          
        </CCardHeader>
        <CCardBody class="item_details connect_chat p-0">
          <CRow v-if="activeChat.thread_id_external !== null" class="h-100 m-0">
            <CCol cols="12" lg="12" class="p-0">              
              <div class="d-flex flex-column align-items-center p-2_5">
                <div v-if="activeChat.thread_type_tag === 'connect_chat'">                    
                  <div v-if="activeChat.participant.profile_image" class="profile_image top" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + activeChat.participant.user_id_external + '/' + activeChat.participant.profile_image + '/' + clientToken + ')' }"></div>
                  <div v-else class="profile_icon top">
                    <userProfileAvatar :username="activeChat.participant.name" :size="150"/>
                  </div>
                </div>
                <div v-else-if="activeChat.thread_type_tag === 'connect_group_chat'" class="image_upload position-relative" v-bind:class="{'uploaded' : activeChatImageUrl}">
                  <div v-if="activeChatImageUrl" 
                       @click="activeChat.user_thread_role_tag === 'connect.chat.admin' ? uploadChatImage() : null" 
                       v-bind:style="{ backgroundImage: 'url(' + activeChatImageUrl + ')' }"
                       class="item_image top"
                       v-bind:class="{'pointer' : activeChat.user_thread_role_tag === 'connect.chat.admin'}">
                  </div>
                  <div v-else 
                       @click="activeChat.user_thread_role_tag === 'connect.chat.admin' ? uploadChatImage() : null"  
                       class="item_icon top"
                       v-bind:class="{'pointer' : activeChat.user_thread_role_tag === 'connect.chat.admin'}">
                    <i class="fa-solid fa-people-group"></i>
                  </div>
                  
                  <div v-if="activeChat.user_thread_role_tag === 'connect.chat.admin'" class="image_upload_notice pointer d-flex align-items-center justify-content-center flex-column" @click="uploadChatImage()">
                    <i class="fa-solid fa-camera mb-1"></i>
                    <span>{{activeChatImageUrl ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
                  </div>

                  <CInputFile id="chatImageUpload" accept="image/x-png,image/gif,image/jpeg" :value="activeChat.image" @change="onChatImageChange" hidden class="m-0"/>                    
                </div>
                <div class="w-100 mt-2_5 item_details_title">            
                  <div v-if="activeChat.thread_type_tag === 'connect_chat'" class="d-flex align-items-center justify-content-center">        
                    <h2>{{activeChat.participant.name}}</h2>
                  </div>
                  <div v-if="activeChat.thread_type_tag === 'connect_group_chat'">
                    <quick-edit v-if="activeChat.user_thread_role_tag === 'connect.chat.admin'"
                                @input="updateChat(activeChat.thread_id_external);"
                                type="text"
                                mode="ignore"
                                :buttonOkText="$t('Save')"
                                :buttonCancelText="$t('Dismiss')"
                                v-model="activeChat.subject">

                      <div class="d-flex align-items-center justify-content-center">
                        <h2 class="m-0">{{activeChat.subject}}</h2>
                        <div class="d-flex align-items-center ml-2">
                          <i class="fa-solid fa-pen"/>
                        </div>
                      </div>
                    </quick-edit>
                    <div v-else class="d-flex align-items-center justify-content-center">
                      <h2>{{activeChat.subject}}</h2>
                    </div>
                  </div>                
                </div>
              </div>
              <hr class="m-0" v-bind:class="{'d-none' : activeChat.thread_type_tag !== 'connect_group_chat'}">
              <div v-if="activeChat.thread_type_tag === 'connect_group_chat'" class="p-2_5 item_details_description">
                <div class="mb-2">
                  <span><b>{{$t('common.Description')}}</b></span>
                </div>
                <div v-if="activeChat.user_thread_role_tag === 'connect.chat.admin'">
                  <quick-edit @input="updateChat(activeChat.thread_id_external);"
                              type="textarea"
                              rows="3"
                              mode="ignore"
                              :buttonOkText="$t('Save')"
                              :buttonCancelText="$t('Dismiss')"
                              v-model="activeChat.description">

                    <div class="d-flex align-items-center">
                      <span v-line-clamp="2" class="flex-grow-1">{{activeChat.description ? activeChat.description : $t('common.Click_to_set_description')}}</span>
                      <div class="d-flex align-items-center ml-2">
                        <i class="fa-solid fa-pen"/>
                      </div>
                    </div>
                  </quick-edit>
                </div>
                <div v-else class="d-flex align-items-center">
                  <span v-line-clamp="2">{{activeChat.description}}</span>
                </div>                
              </div>
              <hr class="m-0" v-bind:class="{'d-none' : activeChat.thread_type_tag !== 'connect_group_chat'}">
              <div v-if="activeChat.thread_type_tag === 'connect_group_chat'" class="p-2_5">
                <div class="d-flex align-items-center">
                  <span class="flex-grow-1"><b>{{$t('common.Participants')}} ({{activeChat.participants.length}})</b></span>
                  <div v-if="activeChat.user_thread_role_tag === 'connect.chat.admin'" 
                       class="d-flex align-items-center ml-2 pointer"
                       @click="activeChatModal = true; getChatParticipants();">
                    <i class="fa-solid fa-pen"/>
                  </div>
                </div>
              </div>
              <div v-if="activeChat.thread_type_tag === 'connect_group_chat'" class="flex-grow-1 participants_container">
                <div v-for="(participant, index) in activeChat.participants" :key="index" class="d-flex align-items-center participant p-2_5">
                  <div v-if="participant.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + participant.user_id_external + '/' + participant.profile_image + '/' + clientToken + ')' }"></div>
                  <div v-else class="profile_icon">
                    <userProfileAvatar :username="participant.name" :size="30"/>
                  </div>
                  <div class="flex-grow-1 ml-2">
                    <span>{{participant.name}}</span>
                  </div>
                  <div v-if="participant.thread_role === 'connect.chat.admin'" class="ml-2">
                    <b-tag class="detail">{{ $t('common.Admin') }}</b-tag>
                  </div>
                </div>
              </div>              
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('connect.Select_chat_to_view_details')}}</span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <b-modal v-if="activeChat.thread_type_tag === 'connect_group_chat'" :can-cancel="['x']" :active.sync="activeChatModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('common.Participants')}} {{activeChat.subject}}
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol cols="12" lg="12">
              <label class="mb-2_5"><b>{{$t('connect.Specify_chat_employees')}}</b></label>
              <multiselect ref="chatEmployees"
                            class="data_table"
                            v-model="activeChat.employees" 
                            :options="employees" 
                            :multiple="true"                
                            :placeholder="$t('common.Select_user')" 
                            :selectLabel="$t('common.Add_user')" 
                            :selectedLabel="$t('Added')"
                            :deselectLabel="$t('common.Remove_user')"
                            track-by="user_id_external" 
                            label="label"                        
                            @search-change="asyncFindParticipant"
                            @input="getChatParticipants();">
                <span slot="noResult">{{$t('common.no_users_found')}}</span>
                <span slot="noOptions">{{$t('common.start_typing_to_search')}}</span>
              </multiselect>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label class="mb-2_5"><b>{{$t('connect.Specify_chat_target_groups')}}</b></label>
              <multiselect ref="chatTargetGroups"
                            class="data_table"
                            v-model="activeChat.target_groups" 
                            :options="targetGroups" 
                            :multiple="true"                
                            :placeholder="$t('common.Select_group')" 
                            :selectLabel="$t('common.Add_group')" 
                            :selectedLabel="$t('Added')"
                            :deselectLabel="$t('common.Remove_group')"
                            track-by="group_id" 
                            label="title"
                            @search-change="asyncFindTargetGroup"
                            @input="getChatParticipants();">
                <span slot="noResult">{{$t('common.no_groups_found')}}</span>
                <span slot="noOptions">{{$t('common.start_typing_to_search')}}</span>
              </multiselect>
            </CCol>
          </CRow>
          <div v-if="(activeChat.employees.length > 0 || activeChat.target_groups.length > 0) && chatParticipants.length > 0">
            <CRow class="group_members m-0">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <span><b>{{$t("common.Participants") }} <span>({{chatParticipants.length}})</span></b></span>
              </CCol>
            </CRow>          
            <CRow class="group_member_container tile-container pt-0 m-0">
              <CCol cols="4" xl="4" lg="4" md="4" sm="12" v-for="employee in currentPageEmployees[currentParticipantPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
                <CCard class="member">
                  <CCardBody>
                    <div class="d-flex align-items-center">
                      <div v-if="employee.profile_image" class="profile_image mr-2_5" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken + ')' }"></div>
                      <div v-else class="profile_icon mr-2_5">
                        <userProfileAvatar :username="employee.name" :size="40"/>
                      </div>
                      <div>
                        <span v-line-clamp="1"><b>{{employee.name}}</b></span>
                        <span v-line-clamp="1" class="meta">{{employee.team_name}} ({{employee.department_name}})</span>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow v-if="chatParticipants.length > participantsPerPage" class="m-0">
              <CCol cols="12" md="12" class="pt-0">
                <v-pagination class="justify-content-end" v-model="currentParticipantPage" :length="participantPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
              </CCol>
            </CRow>
          </div>
          <div v-else-if="(activeChat.employees.length > 0 || activeChat.target_groups.length > 0) && chatParticipants.length === 0">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <span>{{$t('common.No_participants_found')}}</span>
              </CCol>
            </CRow>  
          </div>          
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="updateChat(activeChat.thread_id_external); activeChatModal = false;"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
          <CButton color="secondary" @click="activeChatModal = false;"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>          
        </CCardFooter>
      </CCard>
    </b-modal>    
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import FileUpload from '@/components/upload/FileUpload.vue';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'Chats',
  components: {
    QuickEdit,
    Multiselect,
    FileUpload,
    loadingSpinner,
    noPermission,
    userProfileAvatar
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      chatsToShow: this.$t('connect.All_chats'),
      chats: [],
      chatParticipants: [],
      employees: [],
      targetGroups: [],      
      userData: {},
      activeChat: { 
        thread_id_external: null,
        employees: [],
        target_groups: []
      },
      activeChatMessages: [],
      activeChatMessagesHidden: false,
      activeChatMessagesLoading: false,
      activeChatDetailsHidden: true,
      activeChatImageUrl: null,
      activeChatModal: false,
      allHistoryMessagesLoaded: false,
      chatMessageInterval: null,
      chatReplyButtonDisabled: false,
      showActionsDropdown: false,
      showChatDropdown: false,
      attachmentTypes: [],      
      contentSearchTerm: '',      
      newChatReply: {
        reply: "",
        attachments: []        
      },           
      selectedAttachmentTypeTag: null,
      selectedAttachmentType: null,
      showAttachmentTypes: false, 
      addNewReplyAttachment: false,
      maxImageFilesize: 10485760,
      maxDocumentFilesize: 10485760,
      maxGridImages: 3,
      lightboxImages: [],
      lightboxActiveIndex: null,
      maxCount: 1000,
      remainingCount: 1000,
      currentParticipantPage: 1,
      currentPageEmployees: {},
      participantsPerPage: 9,
      participantPages: 0      
    }
  },  
  methods: {
    checkGroupedMessage(index) {
      if(index > 0) {
        let message = this.activeChatMessages[index];
        let previousMessage = this.activeChatMessages[index-1];
        
        if((message.user_id_external === previousMessage.user_id_external) && (message.reply_date === previousMessage.reply_date)) {
          return true;
        } else {
          return false;
        }
      }
    },    
    getChats() {
      let chatsPath = null;

      if(this.chatsToShow === this.$t('connect.All_chats')) {
        chatsPath = 'chats';
      } else if(this.chatsToShow === this.$t('connect.Personal_chats')) {
        chatsPath = 'chats/personal';
      } else if(this.chatsToShow === this.$t('connect.Group_chats')) {
        chatsPath = 'chats/group';
      } else if(this.chatsToShow === this.$t('connect.Archived_chats')) {
        chatsPath = 'chats/archived';
      }

      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/' + chatsPath)
      .then(res => {
        this.chats = res.data.data;

        if(localStorage.getItem('connectChatToActivate') !== null) {
          let threadIdExternal = localStorage.getItem('connectChatToActivate');
          // Get chat details and messages
          this.getChatDetails(threadIdExternal, false);
          this.getChatMessages(threadIdExternal, true);
          // Remove connectChatToActivate from localStorage      
          localStorage.removeItem('connectChatToActivate');
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getChatData(threadIdExternal, updateChatDetails, hideChatMessages) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + threadIdExternal)
      .then(res => {
        this.activeChat = res.data.data;
        // Get the most recent chat messages
        if(this.activeChat.thread_id_external) this.getChatMessages(this.activeChat.thread_id_external, hideChatMessages);
        // Set the active chat image if available
        if(this.activeChat.thread_type_tag === 'connect_group_chat' && this.activeChat.thread_image_id !== null) {
          this.activeChatImageUrl = this.apiBaseUrl + '/v1/common/cdn/file/image/connect-chat/' + this.activeChat.thread_id_external + '/' + this.activeChat.thread_image_id + '/' + this.clientToken;
        } else {
          this.activeChatImageUrl = null;
        }
        // Get the chat index
        let chatIndex = this.chats.findIndex(x => x.thread_id_external == threadIdExternal);
        // Update the update_available value of the chat        
        this.chats[chatIndex].update_available = false;
        // Update the data of the chat is necessary
        if(updateChatDetails) {          
          this.chats[chatIndex].subject = this.activeChat.subject;
          this.chats[chatIndex].thread_image_id = this.activeChat.thread_image_id;
          this.chats[chatIndex].last_message = this.activeChat.last_message;
          this.chats[chatIndex].participants = this.activeChat.participants;
        }    
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getChatMessages(threadIdExternal, hideMessages) {
      // Update the activeChatMessagesHidden value
      if(hideMessages) this.activeChatMessagesHidden = true;
      // Set the start index
      let currentItemNumber = 0;
      // Update allHistoryMessagesLoaded value
      this.allHistoryMessagesLoaded = false;
      // Get the chat messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + threadIdExternal + '/messages/' + currentItemNumber)
      .then(res => {
        this.activeChatMessages = res.data.data;      
        // Emit connect_chat_messages_read event
        this.$bus.$emit('connect_chat_messages_read');        
        // Wait 250ms before scrolling to the last message
        setTimeout(function() {
          let chatContainerHeight = this.$refs.itemMessageContainer.clientHeight;
          let chatScrollHeight = this.$refs.itemMessageContainer.scrollHeight;

          if((chatContainerHeight === chatScrollHeight)) {
            // Clear the interval if one is already running
            if(this.chatMessageInterval) clearInterval(this.chatMessageInterval);
            // Start the new interval
            this.chatMessageInterval = setInterval(() => this.getNewChatMessages(), 5000);
          } else {
            this.$refs.itemMessageContainer.scrollTo({top: chatScrollHeight})
          }
          // Show the loader if the first batch contains 10 messages already
          if(this.activeChatMessages.length == 10) this.activeChatMessagesLoading = true;
          // Wait another 500ms before updating the activeChatMessagesHidden value
          setTimeout(function(){  
            this.activeChatMessagesHidden = false;
          }.bind(this), 500);
        }.bind(this), 250);
      })
      .catch(err => {
        console.error(err);
      });
    },
    updateChatMessages() {
      let chatScrollPosition = this.$refs.itemMessageContainer.scrollTop + this.$refs.itemMessageContainer.clientHeight;
      let chatScrollHeight = this.$refs.itemMessageContainer.scrollHeight;
      // Get history messages when chat is scrolled to top
      if(this.allHistoryMessagesLoaded === false && this.$refs.itemMessageContainer.scrollTop === 0) this.getHistoryChatMessages();    
      // Get new messages when chat is scrolled to bottom
      if((chatScrollPosition > (chatScrollHeight - 25)) && (chatScrollPosition < (chatScrollHeight + 25))) {
        // Clear the interval if one is already running
        if(this.chatMessageInterval) clearInterval(this.chatMessageInterval);
        // Start the new interval
        this.chatMessageInterval = setInterval(() => this.getNewChatMessages(), 5000);
      } else {
        clearInterval(this.chatMessageInterval);
      }
    },
    getHistoryChatMessages() {
      var initialHeight = this.$refs.itemMessageContainer.scrollHeight;
      // Get the current number of messages
      let currentItemNumber = this.activeChatMessages.length;
      // Get the chat messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + this.activeChat.thread_id_external + '/messages/' + currentItemNumber)
      .then(res => {
        let additionalMessages = res.data.data;
        // Check if there are additional chat messages available
        if(additionalMessages.length > 0) {
          // If so, add them in front of the array
          this.activeChatMessages.unshift(...additionalMessages)
          // Stick to the current scroll position
          this.$nextTick(() => {            
            this.$refs.itemMessageContainer.scrollTo({top: (this.$refs.itemMessageContainer.scrollHeight - initialHeight)})
          })
        } else {
          // Update allHistoryMessagesLoaded value
          this.allHistoryMessagesLoaded = true;
          // Stop the loader
          this.activeChatMessagesLoading = false;            
        } 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getNewChatMessages() {
      // Get the new chat messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + this.activeChat.thread_id_external + '/messages/new')
      .then(res => {
        let additionalMessages = res.data.data;
        // Check if there are additional chat messages available
        if(additionalMessages.length > 0) {
          // If so, add them in front of the array
          this.activeChatMessages.push(...additionalMessages)
          // Stick to the current scroll position
          this.$nextTick(() => {            
            this.$refs.itemMessageContainer.scrollTo({top: (this.$refs.itemMessageContainer.scrollHeight)})
          })
        }
      })
      .catch(err => {
        console.error(err); 
      });    
    },      
    updateChat(threadIdExternal) {
      let params = {};
      params.chatData = this.activeChat;
      if(params.chatData.subject === "") params.chatData.subject = null;
      if(params.chatData.description === "") params.chatData.description = null;

      let subject = params.chatData.subject;
      let employees = params.chatData.employees;
      let target_groups = params.chatData.target_groups; 

      if(subject && (employees.length > 0 || target_groups.length > 0)) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + threadIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.activeChat.thread_type_tag === 'connect_group_chat' ? this.$t('connect.Group_chat_updated') : this.$t('connect.Chat_updated'), type: 'is-success', duration: 2000 });          
          // Get the chat data
          this.getChatData(threadIdExternal, true, false);
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    getUserData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.userData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    submitChatReply(threadIdExternal) {
      // Set the params      
      let params = {};
      params.replyData = this.newChatReply;
      if(params.replyData.reply === "") params.replyData.reply = null;
      params.replyData.replied_from = 'dashboard';
      
      let reply = params.replyData.reply;
      let attachments = params.replyData.attachments;

      if(reply || attachments.length > 0) {
        // Update the chatReplyButtonDisabled value
        this.chatReplyButtonDisabled = true;
        // Post the reply         
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat/' + threadIdExternal + '/reply', params)
        .then(res => {
          let threadIdExternal = res.data.data.thread_id_external;
          let threadMessageIdExternal =  res.data.data.thread_message_id_external;
          let totalAttachments = this.newChatReply.attachments.length;
          let attachmentsUploaded = 0;

          if(totalAttachments > 0) {
            // Loop through the attachments
            for(var a = 0; a < this.newChatReply.attachments.length; a++) {
              let attachmentsIndex = a;            
              // Create new formdata         
              const formData = new FormData();              
              // Append the image to the formdata
              if(this.newChatReply.attachments[attachmentsIndex].attachment_type_tag === 'image') {
                formData.append("file", this.newChatReply.attachments[attachmentsIndex].image);
              } else if(this.newChatReply.attachments[attachmentsIndex].attachment_type_tag === 'file') {
                formData.append("file", this.newChatReply.attachments[attachmentsIndex].file);
              }
              // Append necessary data to the formdata
              formData.append('attachment_event', 'new_connect_chat_message');
              formData.append('attachment_event_external_id', threadMessageIdExternal);
              formData.append('attachment_type_tag', this.newChatReply.attachments[attachmentsIndex].attachment_type_tag);
              formData.append('attachment_directory', threadIdExternal);
                              
              axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat/attachment', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Update the attachmentsUploaded value
                attachmentsUploaded = attachmentsUploaded + 1;
                // Check if all attachments have been uploaded
                if(attachmentsUploaded === totalAttachments) {
                  // Update the addNewReplyAttachment value
                  this.addNewReplyAttachment = false;
                  // Reset the reply data
                  this.resetNewChatReply();
                  // Get chat data
                  this.getChatData(threadIdExternal, true, false);
                  // Reset the chatReplyButtonDisabled value
                  this.chatReplyButtonDisabled = false;                  
                  // Trigger countdown function
                  this.countdown();
                }
              })
              .catch(err => {
                console.error(err); 
              });            
            }        
          } else {
            // Reset the reply data
            this.resetNewChatReply();
            // Get chat data
            this.getChatData(threadIdExternal, true, false);
            // Reset the chatReplyButtonDisabled value
            this.chatReplyButtonDisabled = false;             
            // Trigger countdown function
            this.countdown();         
          }
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('service.Empty_reply'), type: 'is-danger', duration: 2000 });
      }     
    },
    visibilityChanged(isVisible, entry, threadMessageIdExternal){
      if(this.activeChat && isVisible === true) {
        axios.post('v1/connect/chat/' + this.activeChat.thread_id_external + '/read/message/' + threadMessageIdExternal)
        .catch(err => {
          console.error(err); 
        });
      }
    },    
    getAttachmentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/threads/attachments/types/dashboard/list')
      .then(res => {
        this.attachmentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setAttachmentType(type) {
      // Set the attachment type data
      this.selectedAttachmentTypeTag = type.attachment_type_tag;
      this.selectedAttachmentType = type.attachment_type;
          
      // Auto trigger the file pickers after 250ms
      if(this.selectedAttachmentTypeTag === 'image') {
        setTimeout(function(){
          this.pickImageToUpload();      
        }.bind(this), 250);
      } else if(this.selectedAttachmentTypeTag === 'file') {
        setTimeout(function(){
          this.pickFileToUpload();      
        }.bind(this), 250);
      }
    },
    resetActiveChat() {
      this.activeChat = {
        thread_id_external: null,
        employees: [],
        target_groups: []
      };
    },
    resetNewChatReply() {
      this.newChatReply = {
        reply: "",
        attachments: []
      }
    },
    resetAttachment() {
      this.selectedAttachmentTypeTag = null;
      this.selectedAttachmentType = null;
      // Reset the attachments array of the chat reply
      this.newChatReply.attachments = [];
      // Update the addNewReplyAttachment value
      this.addNewReplyAttachment = false;
      // Update the showAttachmentTypes value
      this.showAttachmentTypes = false;
    },    
    onImagesChange(e) {
      // Get the files
      let files = e.target.files;    
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Check if the filesize is allowed
        if(files[i].size <= this.maxImageFilesize) {          
          // Create a newAttachment object
          let newAttachment = {
            attachment_type_tag: this.selectedAttachmentTypeTag,
            attachment_type: this.selectedAttachmentType,
            image: files[i],
            image_url: URL.createObjectURL(files[i]),
            image_name: files[i].name    
          };
          // And add the newAttachment to the attachments array
          this.newChatReply.attachments.push(newAttachment);
        } else {
          // Notify the user if the filesize exceed the maximum filesize
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(files[i].size) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
        }
      }      
      // Update the showAttachmentTypes value
      this.showAttachmentTypes = false;
      // Force update
      this.$forceUpdate();
    },
    onFilesChange(e) {
      // Get the files
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Check if the filesize is allowed
        if(files[i].size <= this.maxImageFilesize) {          
          // Create a newAttachment object
          let newAttachment = {
            attachment_type_tag: this.selectedAttachmentTypeTag,
            attachment_type: this.selectedAttachmentType,
            file: files[i],
            file_url: URL.createObjectURL(files[i]),
            file_name: files[i].name
          };
          // And add the newAttachment to the attachments array
          this.newChatReply.attachments.push(newAttachment);
        } else {
          // Notify the user if the filesize exceed the maximum filesize    
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(files[i].size) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
        }
      }
      // Update the showAttachmentTypes value
      this.showAttachmentTypes = false;
      // Force update
      this.$forceUpdate();          
    },    
    pickImageToUpload() {
      document.getElementById("imageUpload").click();
    },
    pickFileToUpload() {
      document.getElementById("fileUpload").click();
    },
    formatFileSize(bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    },
    uploadChatImage() {
      document.getElementById("chatImageUpload").click()
    },    
    onChatImageChange() {
      const formData = new FormData();
      const imagefile = document.querySelector('#chatImageUpload');
      formData.append("image", imagefile.files[0]);

      axios.post(process.env.VUE_APP_API_URL + '/v1/common/upload/image/chat/' + this.activeChat.thread_id_external, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {  
        // Update the chat data
        this.getChatData(this.activeChat.thread_id_external, true, false);
      })
      .catch(err => {
        console.error(err); 
      });
    }, 
    openImageLightbox(images, index) {
      // Create a new imageUrls arrau
      let imageUrls = [];
      // Add the image URL's to the imageURLs array
      for(var i = 0; i < images.length; i++) imageUrls.push(images[i].url);      
      // Set the image URl's
      this.lightboxImages = imageUrls;
      // Set the active index
      this.lightboxActiveIndex = index;
    },
    openFileAttachment(fileUrl) {
      window.open(fileUrl);
    },
    downloadFileAttachment(fileName, fileUrl) {
      // Create a new XHR request
      var downloadRequest = new XMLHttpRequest();
      downloadRequest.open('GET', fileUrl, true);
      downloadRequest.responseType = 'blob';

      downloadRequest.onload = function(e) {
        if(this.status == 200) {
          var downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(this.response);
          downloadLink.download = fileName;
          downloadLink.click();
        }
      };
      downloadRequest.send();
    },
    asyncFindParticipant(query) {
      let searchTerm = query;
      this.employees = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.employees = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.targetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.targetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    getChatParticipants() {
      let params = {};
      params.employees = this.activeChat.employees;
      params.target_groups = this.activeChat.target_groups;
      
      if(params.employees.length > 0 || params.target_groups.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat/participants', params)
        .then(res => {
          this.chatParticipants = res.data.data;
          // Set the pagination to the first page
          this.currentParticipantPage = 1;
          // Reset the participantPages value
          this.participantPages = 0;
          // Loop through the participants to set the pagination            
          for (let i = 0; i < this.chatParticipants.length; i = i + this.participantsPerPage) {
            this.currentPageEmployees[this.participantPages] = this.chatParticipants.slice(i, i + this.participantsPerPage);
            this.participantPages++;
          }
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.chatParticipants = [];
      }
    },
    countdown() {
      this.remainingCount = this.maxCount - this.newChatReply.reply.length;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }      
  },
  computed: {
    searchedChats() {
      return this.chats.filter((chat) => {
        return (chat.subject && chat.subject.toLowerCase().includes(this.contentSearchTerm.toLowerCase())) || 
               (chat.thread_type_tag === 'connect_chat' && chat.participant.name.toLowerCase().includes(this.contentSearchTerm.toLowerCase())) ||
               (chat.thread_type_tag === 'connect_group_chat' && chat.participants.some((subElement) => subElement.name.toLowerCase().includes(this.contentSearchTerm.toLowerCase())))
      });
    } 
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getPlatformPermissions();
    this.getChats();
    this.resetActiveChat();
    this.resetNewChatReply();
    this.getAttachmentTypes();
    this.getUserData();
  
    this.$bus.$on('update_connect_chats', () => {
      this.getChats();
    });

    this.$bus.$on('open_connect_chat', (threadIdExternal) => {
      this.getChatData(threadIdExternal, false, true);
    })    
  },
  beforeDestroy() {
    this.$bus.$off('update_connect_chats');
    this.$bus.$off('open_connect_chat');
    // Clear the chatMessageInterval interval
    clearInterval(this.chatMessageInterval);
  }
}
</script>
